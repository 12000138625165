import React, { useState } from 'react'
import Breadcrum from '../Common/Breadcrum'
import { useForm } from 'react-hook-form';

const Contact = () => {
    document.title = "Contact Me - Nishant Thummar";
    const [signup, setsignup] = useState({ name: '', email: '', phone: '', message: '' });
    const { register, handleSubmit, formState: { errors } } = useForm();  // validation for login form       
    const onSubmit = data => { 
    }
    const getVal = (e) => {
        setsignup({ ...signup, [e.target.name]: e.target.value });
    } 

    return (
        <>
            <Breadcrum title="Contact Me" />
            <section className="section contact-me has-style-01">
                <div className="shape">
                    <img className="shape-03" src="assets/images/shape/shape-03.png" alt="shape" />
                    <img className="shape-07" src="assets/images/shape/shape-05.png" alt="shape" />
                    <img className="shape-09" src="assets/images/shape/shape-03.png" alt="shape" />
                </div>
                <div className="container">
                    <div className="row align-items-center justify-content-between">
                        <div className="col-lg-6">
                            <div className="heading-title">
                                <h3>Contact Me</h3>
                                <h2>Let's Work together</h2>
                            </div>
                            <p>Need A Right-Fit For Your Project? Feel Free To Contact Me For A Discussion.</p>
                            <ul className="info">
                                <li><i className="fa fa-map-marker" />Surat - India</li>
                                <li><i className="fi fi-rr-envelope" /><a href="mailto:nishantthummar005@gmail.com">nishantthummar005@gmail.com</a></li>
                                <li><i className="fa-brands fa-skype" /><a href="https://join.skype.com/invite/YHWjzsHCZe1W">live:nishantthummar05</a></li>
                            </ul>
                            <ul className="social d-flex">
                                <li><a rel="noreferrer" href="https://www.linkedin.com/in/nishant-thummar-798a54a3/" target={'_blank'}><i className="fa-brands fa-linkedin-in" /></a></li>
                                <li><a rel="noreferrer" href="https://dribbble.com/nishantthummar005" target={'_blank'}><i className="fa-brands fa-dribbble" /></a></li>
                                <li><a rel="noreferrer" href="https://www.behance.net/nishantthummar005" target={'_blank'}><i className="fa-brands fa-behance" /></a></li>
                                <li><a rel="noreferrer" href="https://www.facebook.com/n.j.thummar05" target={'_blank'}><i className="fa-brands fa-facebook-f" /></a></li>
                                <li><a rel="noreferrer" href="https://www.instagram.com/nishant_thummar/" target={'_blank'}><i className="fa-brands fa-instagram" /></a></li>
                                <li><a rel="noreferrer" href="https://github.com/nishantthummar005" target={'_blank'}><i className="fa-brands fa-github" /></a></li>
                            </ul>
                        </div>
                        <div className="col-lg-6">
                            <div className="contact_form">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <form method="post" noValidate onSubmit={handleSubmit(onSubmit)} autoComplete="off" className="contact__form">
                                            {/* form message */}
                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="alert alert-success contact__msg" style={{ display: 'none' }} role="alert">
                                                        Your message was sent successfully.
                                                    </div>
                                                </div>
                                            </div>
                                            {/* end message */}
                                            {/* form element */}
                                            <div className="row">
                                                <div className="col-12 form-group">
                                                    <i className="fi fi-rr-user" />
                                                    <input name="name" onKeyUp={getVal} type="text" className="form-control" placeholder="Name" {...register("name", {
                                                        required: true
                                                    })} />
                                                    {errors.name && errors.name.type === "required" && (
                                                        <span className='alert p-1 mt-2 font-12 alert-warning' role="alert">This is required field!</span>
                                                    )}
                                                </div>
                                                <div className="col-12 form-group">
                                                    <i className="fi fi-rr-envelope" />
                                                    <input name="email" onKeyUp={getVal} type="email" className="form-control" placeholder="Email"  {...register("email", {
                                                        required: true
                                                    })} />
                                                    {errors.email && errors.email.type === "required" && (
                                                        <span className='alert p-1 mt-2 font-12 alert-warning' role="alert">This is required field!</span>
                                                    )}
                                                </div>
                                                <div className="col-12 form-group">
                                                    <i className="fi fi-rr-call-incoming" />
                                                    <input name="phone" onKeyUp={getVal} type="text" className="form-control" placeholder="Phone"  {...register("phone", {
                                                        required: true
                                                    })} />
                                                    {errors.phone && errors.phone.type === "required" && (
                                                        <span className='alert p-1 mt-2 font-12 alert-warning' role="alert">This is required field!</span>
                                                    )}
                                                </div>
                                                {/* <div className="col-12 form-group">
                                                    <i className="fi fi-rr-pencil" />
                                                    <input name="subject" onKeyUp={getVal} type="text" className="form-control" placeholder="Subject" />
                                                </div> */}
                                                <div className="col-12 form-group">
                                                    <i className="fi fi-rr-document" />
                                                    <textarea name="message" onKeyUp={getVal} className="form-control" rows={3} placeholder="Message"  {...register("message", {
                                                        required: true
                                                    })} />
                                                    {errors.message && errors.message.type === "required" && (
                                                        <span className='alert p-1 mt-2 font-12 alert-warning' role="alert">This is required field!</span>
                                                    )}
                                                </div>
                                                <div className="col-12"><input name="submit" type="submit" className="btn btn-generic" defaultValue="Send Message" /></div>
                                            </div>
                                            {/* end form element */}
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Contact