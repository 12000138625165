import React from 'react'
import { Link, useLocation } from 'react-router-dom';

const Header = () => {
    const location = useLocation();
    const currentPage = location.pathname.substring(1);
    return (
        <>
            {/* start navbar */}
            <nav className="navbar navbar-expand-lg navbar-dark bg-transparent">
                <div className="container">
                    <Link className="navbar-brand font-30" to="/">
                        {/* <img width={160} src="assets/images/icon/logo.png" alt="logo" /> */}
                        <span>N</span>ishant <span>T</span>hummar
                    </Link>
                    <button className="navbar-toggler" id="js-nav-trigger" type="button" data-bs-toggle="collapse" data-bs-target="#navbarContent" aria-controls="navbarContent" aria-expanded="false" aria-label="Toggle">
                        <span />
                    </button>
                    {/* .navbar-collapse */}
                    <div className="collapse navbar-collapse" id="navbarContent">
                        <ul className="navbar-nav ms-auto">
                            <li className="nav-item"><Link className={(currentPage === '') ? 'active nav-link' : 'nav-link'} aria-current="page" to="/">home</Link></li>
                            <li className="nav-item"><Link className={(currentPage === 'about') ? 'active nav-link' : 'nav-link'} to="/about" data-value="about-me">about me</Link></li>
                            <li className="nav-item"><Link className={(currentPage === 'services') ? 'active nav-link' : 'nav-link'} to="/services" data-value="services">services</Link></li>
                            <li className="nav-item"><Link className={(currentPage === 'portfolio') ? 'active nav-link' : 'nav-link'} to="/portfolio">portfolio</Link></li>
                            <li className="nav-item"><Link className={(currentPage === 'contact') ? 'active nav-link' : 'nav-link'} to="/contact">contact me</Link></li>
                        </ul>
                    </div>
                    {/* .navbar-collapse */}
                </div>
            </nav>
            {/* end navbar */}

        </>
    )
}

export default Header