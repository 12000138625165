import React from 'react'
import Breadcrum from '../Common/Breadcrum'

const Services = () => {
    document.title = "Services - Nishant Thummar";

    return (
        <>
            <Breadcrum title="Services" />
            {/* start what-do */}
            <section id="services" className="section what-do has-style-01">
                <div className="shape">
                    <img className="shape-03" src="assets/images/shape/shape-03.png" alt="shape" />
                    <img className="shape-04" src="assets/images/shape/shape-what-i-do2.png" alt="shape" />
                    <img className="shape-05" src="assets/images/shape/shape-what-i-do1.png" alt="shape" />
                    <img className="shape-06" src="assets/images/shape/shape-what-i-do4.png" alt="shape" />
                </div>
                <div className="container">
                    <div className="row align-items-center justify-content-between flex-row-reverse">
                        {/* start heading-title */}
                        <div className="col-lg-5 col-md-6 col-12">
                            <div className="heading-title">
                                <h3>what i do</h3>
                                <h2>Better design, <br /> Better expericences</h2>
                                <p>
                                    I’ll help you make sense of what you need so we can create something that will make sense for your customers. I primarily work in Adobe Photoshop, Adobe Illustrator, Adobe InDesign, Canva Pro, CorelDRAW, and Microsoft PowerPoint.
                                    <br /><br/>
                                    From custom theme designs to e-commerce and custom plugin development, I will help you transform your personal or business concept into a successful MERN/ CI-PHP/ Laravel/ Shopify/ WordPress-based website or Any platform of your own choice.
                                    <br /><br
/>
                                    I am responsible for creating design solutions that have a high visual impact. The role involves listening to clients and understanding their needs before making design decisions.
                                </p>
                            </div>
                        </div>
                        {/* end heading-title */}
                        {/* start hero-content */}
                        <div className="col-lg-6 col-md-6 col-12 hero-content">
                            <div className="row align-items-center justify-content-between">
                                <div className="col-lg-6 col-md-12">
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="box">
                                                <div className="icon"><i className="fa-solid fa-mobile-screen" /></div>
                                                <h4>Mobile App <br /> Design</h4>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="box">
                                                <div className="icon"><i className="fa-solid fa-desktop" /></div>
                                                <h4>Website <br /> Development</h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-12">
                                    <div className="box">
                                        <div className="icon"><i className="fa-solid fa-pencil" /></div>
                                        <h4>Prototyping <br /> &amp; wireframing</h4>
                                    </div>
                                    <div className="box">
                                        <div className="icon"><i className="fa-solid fa-paintbrush" /></div>
                                        <h4>Logo <br /> Design</h4>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-12">
                                    <div className="box">
                                        <div className="icon"><i className="fa-solid fa-pencil" /></div>
                                        <h4>Content <br /> &amp; Writing</h4>
                                    </div>  
                                </div>
                            </div>
                        </div>
                        {/* end hero-content */}
                    </div>
                </div>
            </section>
            {/* end what-do */}

        </>
    )
}

export default Services