import React from "react";
import { Link } from "react-router-dom";
import Breadcrum from "../Common/Breadcrum";

const About = () => {
  document.title = "About me - Nishant Thummar";
  return (
    <>
      <Breadcrum title="About Me" />
      {/* start about-me */}
      <section id="about-me" className="section about-me has-style-01">
        <div className="shape">
          <img
            className="shape-04"
            src="assets/images/shape/shape-what-i-do2.png"
            alt="shape"
          />
          <img
            className="shape-07"
            src="assets/images/shape/shape-05.png"
            alt="shape"
          />
        </div>
        <div className="container">
          <div className="row align-items-center justify-content-between">
            {/* start heading-title */}
            <div className="col-lg-12">
              <div className="heading-title">
                <h3>About Me</h3>
                <h2>
                  Hi, Im here <br /> to help your next project
                </h2>
                <p>
                  Over the last seven years, I have designed and developed a
                  wide range of Websites & Applications using MERN, Codeigniter,
                  wordpress, PHP, HTML/5, CSS/3, REACT, JAVASCRIPT, JQUERY, AJAX
                  and MySQL, etc. I also make PSD to HTML, ADOBE XD to HTML,
                  FIGMA to HTML, in addition I have developed and designed some
                  Android applications as well.
                </p>
              </div>
              <ul className="row row-cols-md-2 row-cols-2 info">
                <li>
                  <i className="fi fi-rr-check" /> Problem Solving
                </li>
                <li>
                  <i className="fi fi-rr-check" /> Creative Idea
                </li>
                <li>
                  <i className="fi fi-rr-check" /> Search A Lot
                </li>
                <li>
                  <i className="fi fi-rr-check" /> Well Experienced
                </li>
                <li>
                  <i className="fi fi-rr-check" /> Quality solution
                </li>
                <li>
                  <i className="fi fi-rr-check" /> High quality
                </li>
                <li>
                  <i className="fi fi-rr-check" /> Easy Communication
                </li>
                <li>
                  <i className="fi fi-rr-check" /> Fast Delivery
                </li>
              </ul>
              <ul>
                <li>✔ Experienced Full Stack Developer</li>
                <li>✔ An Ambitious Problem Solver</li>
                <li>✔ Logo, Slider & Banner Designer</li>
                <li>✔ Expert in Figma to HTML, Adobe to HTML</li>
                <li>✔ Expert in Theme and Template Designing</li>
                <li>✔ Desktop Game Design</li>
                <li>✔ Complex website builder</li>
                <li>✔ Real Estate Platform </li>
                <li>✔ eCommerce & Marketplace Website Builder</li>
                <li>✔ Multi-tenancy SaaS Project</li>
                <li>✔ An Excellent Team Worker</li>
                <li>✔ Easy To Communicate - 24 Hours Availability</li>
              </ul>
              {/* end link */}
            </div>
            {/* end heading-title */}
            <div className="col-lg-12">
              <div className="mt-20">
                <h4 className="mb-10">⭐️ 𝗦𝗽𝗲𝗰𝗶𝗮𝗹𝘁𝘆:</h4>
                PHP | MERN Stack | CodeIgniter | Shopify | WordPress | eCommerce
                | CMS | React | HTML5 | Canvas | Animation | SCSS | CSS3 |
                Bootstrap | SASS | jQuery Animate
              </div>
              <div className="mt-20">
                <h4 className="mb-10">⭐️ 𝗧𝗼𝗼𝗹𝘀:</h4>
                Git | GitHub | BitBucket | Jira | Trello | NetBeans | AWS | VS
                Code | Photoshop | Adobe | Figma
              </div>
              <div className="mt-20">
                👨‍🎓 Need a right-fit for your project? Feel free to contact me
                for a discussion.
              </div>
              {/* start link */}
              <ul className="link d-flex mt-40">
                <li>
                  <Link className="btn btn-generic" to="/portfolio">
                    My Portfolio
                  </Link>
                </li>
                <li>
                  <a
                    className="btn btn-generic ml-20"
                    target="_blank"
                    rel="noreferrer"
                    href="https://codecanyon.net/user/nishantthummar"
                  >
                    Code Canyon Store
                  </a>
                </li>
                <li>
                  <a
                    className="btn btn-generic ml-20"
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.upwork.com/freelancers/~0189a8c3bb22c08ecd"
                  >
                    Upwork Profile
                  </a>
                </li>
                <li>
                  <a
                    className="btn btn-link"
                    href="assets/resume/NishantThummarResume.pdf"
                    download={"Nishant Thummar Resume"}
                  >
                    download cv <i className="fa-solid fa-download" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      {/* end about-me */}
    </>
  );
};

export default About;
