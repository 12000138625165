import React from 'react'
import Breadcrum from '../Common/Breadcrum'
const Comment = () => {
    document.title = "Contact us - Nishant Thummar";
    return (
        <>
            <Breadcrum title="Add Comment" />
            {/* start comment */}
            <section className="section comment has-style-01">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 text-center">
                            <div className="icon-top">
                                <img src="assets/images/icon/star.png" alt="icon" />
                            </div>
                        </div>
                        <div className="col-lg-8 col-md-10 col-sm-12 col-12">
                            <form className="comment-form" method="post" action="#">
                                {/* form message */}
                                <div className="row">
                                    <div className="col-12">
                                        <div className="alert alert-success contact__msg" style={{ display: 'none' }} role="alert">
                                            Your comment was sent successfully.
                                        </div>
                                    </div>
                                </div>
                                {/* end message */}
                                {/* form element */}
                                <div className="row">
                                    <div className="col-12 form-group text-center">
                                        <div className="rate">
                                            <input type="radio" id="star5" name="rate" defaultValue={5} />
                                            <label htmlFor="star5" title="5 stars">5 stars</label>
                                            <input type="radio" id="star4" name="rate" defaultValue={4} />
                                            <label htmlFor="star4" title="4 stars">4 stars</label>
                                            <input type="radio" id="star3" name="rate" defaultValue={3} />
                                            <label htmlFor="star3" title="3 stars">3 stars</label>
                                            <input type="radio" id="star2" name="rate" defaultValue={2} />
                                            <label htmlFor="star2" title="2 stars">2 stars</label>
                                            <input type="radio" id="star1" name="rate" defaultValue={1} />
                                            <label htmlFor="star1" title="1 star">1 star</label>
                                        </div>
                                    </div>
                                    <div className="col-12 form-group">
                                        <i className="fi fi-rr-user" />
                                        <input name="name" type="text" className="form-control" placeholder="Your Name" required />
                                    </div>
                                    <div className="col-12 form-group">
                                        <i className="fi fi-rr-envelope" />
                                        <input name="email" type="email" className="form-control" placeholder="Email Address" required />
                                    </div>
                                    <div className="col-12 form-group">
                                        <i className="fi fi-rr-document" />
                                        <textarea name="message" className="form-control" rows={3} placeholder="Message" required defaultValue={""} />
                                    </div>
                                    <div className="col-12"><input name="submit" type="submit" className="btn btn-generic" defaultValue="Send" /></div>
                                </div>
                                {/* end form element */}
                            </form>
                        </div>
                    </div>
                </div>
            </section>
            {/* end comment */}

        </>
    )
}

export default Comment