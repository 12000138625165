import React from 'react'
import { Link } from 'react-router-dom'
import ScrollUp from './ScrollUp'

const Footer = () => {
    return (
        <>
            {/* start footer */}
            <footer className="has-style-01">
                <div className="shape">
                    <img className="shape-03" src="assets/images/shape/shape-03.png" alt="shape" />
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 col-md-7 col-12">
                            <ul className="link-footer">
                                <li><Link to="/">Home</Link></li>
                                <li><Link to="/about">About Me</Link></li>
                                <li><Link to="/comment">Comments</Link></li>
                                <li><Link to="/services">Services</Link></li>
                                <li><Link to="/portfolio">Portfolio</Link></li>
                                <li><Link to="/contact">Contact Me</Link></li>
                            </ul>
                        </div>
                        <div className="col-lg-4 col-md-5 col-12">
                            <ul className="designby">
                                <li><a rel="noreferrer" href="https://nishantthummar.tech/" target={'_blank'}><i className="fa-solid fa-heart" /> Developed By Nishant Thummar</a></li>
                            </ul>
                        </div>
                    </div>
                    <hr />
                    <p className="text-center">© nishant thummar - copyright all right reserved</p>
                </div>
            </footer>
            {/* end footer */}
            <ScrollUp />
        </>
    )
}

export default Footer