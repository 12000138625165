import React from 'react'

const Item = (props) => {
    var categories = props.category;
    var newCate = categories.split(',');
    return (
        <>
            {/* start item-card */}
            <div className={'col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12 mix ' + props.type} key={props.id}>
                <div className="item-card">
                    <h4><a className="title-link" target={'_blank'} rel="noreferrer" href={props.link}>{props.name}</a></h4>
                    <p>{props.description}</p>
                    <ul className="tag-link">
                        <li><a href="/">{newCate[0]}</a></li>
                        <li><a href="/">{newCate[1]}</a></li>
                        <li><a href="/">{newCate[2]}</a></li> 
                    </ul>
                    <div className="item-image">
                        <img src={props.path} alt={props.name} />
                        <a className="link-image" rel="noreferrer" href={props.link} target='_blank'><i className="fi fi-rr-arrow-small-right" /></a>
                    </div>
                </div>
            </div>
            {/* end item-card */}
        </>
    )
}

export default Item