import React from 'react'
import { Link } from 'react-router-dom'

const Breadcrum = (props) => {
    return (
        <>
            {/* start header */}
            <header className="has-style-02">
                <div className="container">
                    <div className="shape">
                        <img className="shape-10" src="assets/images/shape/shape-circle1.png" alt="shape" />
                        <img className="shape-03" src="assets/images/shape/shape-03.png" alt="shape" />
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="breadcrumb">
                                <h2>{props.title}</h2>
                                <ul className="d-flex">
                                    <li><Link to="/">home</Link></li>
                                    <li>/</li>
                                    <li>{props.title}</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            {/* end header */}

        </>
    )
}

export default Breadcrum