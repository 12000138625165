import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Footer from "./Pages/Common/Footer";
import Header from "./Pages/Common/Header";
import Page404 from "./Pages/Error/Page404";
import About from "./Pages/Other/About";
import Comment from "./Pages/Other/Comment";
import Contact from "./Pages/Other/Contact";
import Home from "./Pages/Other/Home";
import Portfolio from "./Pages/Other/Portfolio/Portfolio";
import Services from "./Pages/Other/Services"; 

function Master() {
  return (
    <>
      <Router>
        <Header />
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/about" element={<About />} />
          <Route exact path="/contact" element={<Contact />} />
          <Route exact path="/portfolio" element={<Portfolio />} />
          <Route exact path="/services" element={<Services />} />
          <Route exact path="/comment" element={<Comment />} /> 
          <Route path="*" element={<Page404 />} />
        </Routes>
        <Footer />
      </Router>
    </>
  );
}
export default Master;
