const Data = [
  {
    id: 1,
    name: "React Personal Portfolio Theme",
    category: "React Theme, Web Design, Figma",
    type: "web",
    photo: "https://nishantthummar.in/portfolio/nishantthummar.tech.png",
    description:
      "This theme is based on Business Visiting card and Personal portfolio. This theme offers you the best online tool to create high-quality Digital Business Card.",
    link: "https://nishantthummar.in/envato/react/elevate-vcard/",
  },
  {
    id: 2,
    name: "Finance Business Theme",
    category: "Web Design, Front-End Development, Figma",
    type: "web",
    photo: "https://nishantthummar.in/portfolio/business.theme.png",
    description:
      "This theme based on finance business. It's your personal finance assistant who will keep track of your daily expenses and remind to save wisely for future.",
    link: "https://nishantthummar.in/extra_web/acuml/index.html",
  },
  {
    id: 3,
    name: "Fab Addition Online Shopping",
    category: "Ecommerce, Shopify, Product",
    type: "web",
    photo: "https://nishantthummar.in/portfolio/fabaddition.jpg",
    description:
      "This project is based on Online Shopping Ecommerce Platform with multi payment gateway option. Kindly review the site to know more about it.",
    link: "https://fabaddition.com/",
  },
  {
    id: 4,
    name: "Vasundhra Fashion",
    category: "Ecommerce, Shopify, Product",
    type: "web",
    photo: "https://nishantthummar.in/portfolio/vasundhara.png",
    description:
      "This project is based on Online Shopping Ecommerce Platform with multi payment gateway option. Kindly review the site to know more about it.",
    link: "https://vasundhrafashion.com/",
  },
  {
    id: 5,
    name: "MishiHouse -Ecommerce",
    category: "Ecommerce, PHP, Product",
    type: "web",
    photo: "https://nishantthummar.in/portfolio/mishi.png",
    description:
      "This project is based on Single Seller Ecommerce Platform with multi payment gateway option. Kindly review site to know more about it.",
    link: "https://mishihouse.com/",
  },
  {
    id: 6,
    name: "Aviom Diamond",
    category: "Ecommerce, Laravel-PHP, Product",
    type: "web",
    photo: "https://nishantthummar.in/portfolio/aviom.png",
    description:
      "This project is based on Real Diamond Selling and puchasing website portal, Kindly review the site to know more about it.",
    link: "https://aviomlabgrown.com/",
  },
  {
    id: 7,
    name: "Find My Card - Business vCard",
    category: "Business Card, PHP, Digital Card, Services ",
    type: "web",
    photo: "https://nishantthummar.in/portfolio/business.card.png",
    description:
      "Find My Card offers you the best online tool to create high-quality Digital Business Card & Social Media Images, PDF Id Card, Musical Invitation Card ",
    link: "https://findmycard.in/",
  },
  {
    id: 8,
    name: "Sunshine Academy",
    category: "API, PHP, Informative, Appointment",
    type: "web",
    photo: "https://nishantthummar.in/portfolio/sunshine.png",
    description:
      "This project based on Visa & Immigration Consultants. Here, Student can check all the immigration and institute related information.",
    link: "https://sunshineacademys.com/",
  },
  {
    id: 9,
    name: "SEWA APP",
    category: "Application, React Native, API",
    type: "mobile",
    photo: "https://nishantthummar.in/portfolio/sewa.png",
    description:
      "Sewa in Punjabi language means “Selfless service”. It involves acting selflessly and helping others in a variety of ways, without any reward or personal gain.",
    link: "https://sewa.live",
  },
  {
    id: 10,
    name: "All Shine Car Groomers",
    category: "PHP, Booking, Appointment",
    type: "web",
    photo: "https://nishantthummar.in/portfolio/allshinecargroomers.png",
    description:
      "Welcome to All Shine Car Grooming! We are thrilled to offer you the finest car grooming services tailored to meet your vehicle's needs.",
    link: "https://allshinecargroomers.com/",
  },
  {
    id: 11,
    name: "NZ HVAC Supplies LTD",
    category: "PHP, Product, Enquiry",
    type: "web",
    photo: "https://nishantthummar.in/portfolio/nzhvac.png",
    description:
      "NZ HVAC SUPPLIES ltd. was established in WELLINGTON TAWA, in the year 2016. We have worked hard on our products and services by creating a good customer base and proving our reliability.",
    link: "https://nzhvac.co.nz/",
  },
  {
    id: 12,
    name: "Dynamic Mechanical",
    category: "PHP, Services, Booking, Appointment",
    type: "web",
    photo: "https://nishantthummar.in/portfolio/dynamic-mechanical.png",
    description:
      "Dynamic mechanical are experts providing full and complete heating, ventilation and air conditioning services. We are professional specialists in HVAC design and project management.",
    link: "https://dynamicmech.co.nz/",
  },
  {
    id: 13,
    name: "HVAC Clean",
    category: "PHP, Services, Enquiry",
    type: "web",
    photo: "https://nishantthummar.in/portfolio/hvac-clean.png",
    description:
      "We know that honesty and transparency, coupled with reliable and friendly customer service, is what really builds customer trust.",
    link: "https://hvacclean.co.nz/",
  },
  {
    id: 14,
    name: "T-Build Flooring Retailers",
    category: "E-commerce, PHP, Services, Product, Enquiry",
    type: "web",
    photo: "https://nishantthummar.in/portfolio/flooring.png",
    description:
      "We take the opportunity of introducing the Thakral Group, Founded In 1905. Thakral Group is a Singaporean homegrown conglomerate with operations spanning 25 countries in various business segments.",
    link: "https://www.t-build.co.uk/",
  },
  {
    id: 15,
    name: "Gravitate Overseas",
    category: "PHP, Services, Inforamtive, Enquiry",
    type: "web",
    photo: "https://nishantthummar.in/portfolio/gravitate.png",
    description:
      "Gravitate Overseas is professional, competent, and proficient in advising on international education. It was founded in 2018 with the intention of providing comprehensive and beneficial service.",
    link: "https://gravitateoverseas.com/",
  },
  {
    id: 16,
    name: "Diet Finiti",
    category: "PHP, Services, Enquiry",
    type: "web",
    photo: "https://nishantthummar.in/portfolio/dietfiniti.png",
    description:
      "At Dietfiniti, we are a team of clinical Dietician and Nutritionist from Mumbai, India. We are centered around the principle of Health , Wellness and Sustainability.",
    link: "https://dietfiniti.com/",
  },
  {
    id: 17,
    name: "Art Park India",
    category: "shopify, E-commerce, Product",
    type: "web",
    photo: "https://nishantthummar.in/portfolio/artparkindia.png",
    description:
      "Artparkindia.com  is sister concern firm group of Shaurya Management & Publishing Pvt Ltd, Surat. Gujarat. INDIA working with hobby and premium art range from last 12 years.",
    link: "https://artparkindia.com/",
  },
  {
    id: 18,
    name: "Holiday Farmhouse",
    category: "Property, PHP, Services, Product, Enquiry",
    type: "web",
    photo: "https://nishantthummar.in/portfolio/holidayfarmhouse.png",
    description:
      "Holiday Farmhouse offerred you best and affordable farmhouse, Penthouse, Villa and Private farm property for rent or sale.",
    link: "https://holidayfarmhouse.in/",
  },
  {
    id: 19,
    name: "Caliber Glazing",
    category: "PHP, Services, Product, Enquiry",
    type: "web",
    photo: "https://nishantthummar.in/portfolio/caliberglazing.png",
    description:
      "We provide a range of doors including aluminium, UPVC, bifold and composite. Our new composite doors are both durable and weather resistant and robust.",
    link: "https://caliberglazing.co.uk/",
  },
  {
    id: 20,
    name: "Inspiron",
    category: "PHP, Services, Enquiry",
    type: "web",
    photo: "https://nishantthummar.in/portfolio/inspiron.png",
    description:
      "InspirOn Engineering Private Limited (IEPL) is the world's largest manufacturer of high performance aluminium flyers having manufactured and sold over 5 million of these critical textile components.",
    link: "https://www.inspiron.co.in/",
  },
  {
    id: 21,
    name: "Ayndhaminfo",
    category: "PHP, Job Seeker, Hire Candidate",
    type: "web",
    photo: "https://nishantthummar.in/portfolio/ayndhaminfo.png",
    description:
      "Are you searching for the perfect job opportunity or looking to hire top-notch candidates? Look no further! Our website is designed to connect job seekers and employers in a seamless and efficient manner.",
    link: "https://www.ayndhaminfo.com/",
  },
  {
    id: 22,
    name: "Securewill",
    category: "PHP, Services, Will Writing",
    type: "web",
    photo: "https://nishantthummar.in/portfolio/securewill.png",
    description:
      "Secure Will is not just a will writing platform, its a mission to connect with all the people of India and help them save their Loved Ones.",
    link: "https://securewill.in/",
  },
  {
    id: 23,
    name: "Favara",
    category: "PHP, API, React Native",
    type: "mobile",
    photo: "https://nishantthummar.in/portfolio/favara.png",
    description:
      "It has taken the fearless leadership and infinite contributions of experts round the world to make Favara Drones (favara.in) as global organization.",
    link: "https://favara.in/",
  },
  {
    id: 24,
    name: "Mdotts Tech",
    category: "PHP, Services, Enquiry",
    type: "web",
    photo: "https://nishantthummar.in/portfolio/mdottstech.png",
    description:
      "MDottsTech is the partner of choice for many of the world’s leading enterprises, SMEs and technology challengers. We help businesses elevate their value through custom software development.",
    link: "https://mdottstech.com/",
  },
  {
    id: 25,
    name: "Prayosha Brass",
    category: "PHP, Services, Enquiry",
    type: "web",
    photo: "https://nishantthummar.in/portfolio/prayoshabrass.png",
    description:
      "Prayosha Enterprise is engaged in supply of Precision Turned Components for last 20 years with specialization in advanced Precision Turned Components & Sanitary Parts. ",
    link: "https://prayoshabrass.com/",
  },
  {
    id: 26,
    name: "Varnimelamine",
    category: "PHP, Product, Enquiry",
    type: "web",
    photo: "https://nishantthummar.in/portfolio/varnimelamine.png",
    description:
      "Varni Mela Mine ceaselessly draws inspiration from people's journeys and strives continuously to make life easier with state-of-the-art products. Thus enabling and revolutionising a progressive lifestyle, one innovation at a time. ",
    link: "https://www.varnimelamine.com/",
  },
  {
    id: 27,
    name: "Ssense Beauty",
    category: "PHP, Product, Ecommerce",
    type: "web",
    photo: "https://nishantthummar.in/portfolio/ssense.png",
    description:
      "Ssense Beauty’s goal is to strengthen the natural healing process of skin and hair with products using all natural ingredients. Ssense is founded and owned by a passionate and life-long learner of professional beauty – Aishwari Vasavada. ",
    link: "https://nishantthummar.in/support/",
  },
  {
    id: 28,
    name: "Shivam Pendawala",
    category: "PHP, Product, Ecommerce",
    type: "web",
    photo: "https://nishantthummar.in/portfolio/shivampendawala.png",
    description:
      "Our journey started in 1950; our late Sheth Shri Amarsinh Mavawala initiated Shivam Pendawala in small village Of Borsad in the Gujarat State of India. ",
    link: "https://shivampendawala.com/",
  },
  {
    id: 29,
    name: "Lilaba Labs",
    category: "Wordpress, Product, Ecommerce",
    type: "web",
    photo: "https://nishantthummar.in/portfolio/lilabalabs.png",
    description:
      "Lilaba Analytical Laboratories evolved from Idea to Realization. Idea of starting an Independent Testing lab came with an incident happened In 2011. We became victim of highly adulterated local food and suffered many days in hospital to recover. ",
    link: "https://lilabalabs.com/",
  },
];
export default Data;
