import React from 'react'
import { Link } from 'react-router-dom'

const Home = () => {
    return (
        <>
            {/* start header */}
            <header className="has-style-01">
                {/* start shape */}
                <div className="shape">
                    <img className="shape-01" src="assets/images/shape/shape-04.png" alt="shape" />
                    <img className="shape-02" src="assets/images/shape/shape-01.png" alt="shape" />
                    <img className="shape-10" src="assets/images/shape/shape-02.png" alt="shape" />
                    <img className="shape-07" src="assets/images/shape/shape-05.png" alt="shape" />
                    <img className="shape-03" src="assets/images/shape/shape-03.png" alt="shape" />
                </div>
                {/* end shape */}
                {/* start container */}
                <div className="container">
                    {/* start row */}
                    <div className="row align-items-center justify-content-between">
                        {/* start hero-content */}
                        <div className="col-lg-6 col-md-6 col-12">
                            <div className="hero-content">
                                {/* start info */}
                                <h3 className="title-welcome">hello</h3>
                                <h1>I'M Nishant <br /> Thummar</h1>
                                <h3>MERN &amp; Full Stack Developer</h3>
                                {/* end info */}
                                {/* start link */}
                                <ul className="link d-flex">
                                    <li><Link className="btn btn-generic" to="/contact">hire me</Link></li>
                                    <li><a className="btn btn-link" href="assets/resume/NishantThummarResume.pdf" download={'Nishant Thummar Resume'}>download CV <i className="fa-solid fa-download" /></a></li>
                                </ul>
                                {/* end link */}
                            </div>
                        </div>
                        {/* start hero-content */}
                        {/* start hero-thumb */}
                        <div className="col-lg-5 col-md-6 col-12">
                            <div className="hero-thumb">
                                {/* .skills */}
                                <img className="skills" src="assets/images/header/skills.png" alt="skills" />
                                {/* .experience */}
                                <img className="experience" src="assets/images/header/experience.png" alt="experience" />
                                {/* .play */}
                                <a className="play pulse" href="https://www.youtube.com/c/SongsGarba"><i className="fa-solid fa-play" /></a>
                                {/* .thumb */}
                                <img className="thumb text-right" src="assets/images/header/photo.png" alt="thumb" />
                            </div>
                        </div>
                        {/* end hero-thumb */}
                    </div>
                    {/* end row */}
                </div>
                {/* end container */}
                {/* start social */}
                <ul className="social">
                    <li><Link to="/comment"><i className="fa-regular fa-comment-dots" /></Link></li>
                    <li>Follow Me</li>
                    <li>-</li>
                    <li><a rel="noreferrer" href="https://www.linkedin.com/in/nishant-thummar-798a54a3/" target={'_blank'}><i className="fa-brands fa-linkedin-in" /></a></li>
                    <li><a rel="noreferrer" href="https://dribbble.com/nishantthummar005" target={'_blank'}><i className="fa-brands fa-dribbble" /></a></li>
                    <li><a rel="noreferrer" href="https://www.behance.net/nishantthummar005" target={'_blank'}><i className="fa-brands fa-behance" /></a></li>
                    <li><a rel="noreferrer" href="https://www.facebook.com/n.j.thummar05" target={'_blank'}><i className="fa-brands fa-facebook-f" /></a></li>
                    <li><a rel="noreferrer" href="https://www.instagram.com/nishant_thummar/" target={'_blank'}><i className="fa-brands fa-instagram" /></a></li>
                    <li><a rel="noreferrer" href="https://github.com/nishantthummar005" target={'_blank'}><i className="fa-brands fa-github" /></a></li>
                </ul>
                {/* end social */}
            </header>
            {/* end header */}

        </>
    )
}

export default Home