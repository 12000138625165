import React from 'react'
import Breadcrum from '../../Common/Breadcrum'
import Item from './Item';
import Data from './Data';

const Portfolio = () => { 
    const portData = [...Data].sort((a, b) => b.id - a.id);
    document.title = "Portfolio - Nishant Thummar";
    return (
        <>
            <Breadcrum title="Portfolio" />
            <section className="section portfolio has-style-01">
                <div className="shape">
                    <img className="shape-03" src="assets/images/shape/shape-03.png" alt="shape" />
                    <img className="shape-07" src="assets/images/shape/shape-05.png" alt="shape" />
                </div>
                <div className="container">
                    {/* start heading-title */}
                    <div className="row align-items-center justify-content-between">
                        <div className="col-lg-7 col-md-8 col-12 d-flex">
                            <div className="heading-title">
                                <h3>My Portfolio</h3>
                                <h2>Some of my distinguished works</h2>
                            </div>
                        </div>
                        <div className="col-lg-5 col-md-4 col-12 controls ">
                            <button type="button" data-filter="all">all portfolio</button>
                            <button type="button" data-filter=".mobile">Mobile App</button>
                            <button type="button" data-filter=".web">Web Design</button>
                        </div>
                    </div>
                    {/* end heading-title */}
                    {/* start item */}
                    <div className="row auction-filter justify-content-center">
                        {portData.map((val) => {
                            return ( 
                                <Item
                                    key={val.id} 
                                    type={val.type} 
                                    category={val.category} 
                                    name={val.name}
                                    path={val.photo}
                                    link={val.link}
                                    description={val.description}
                                />
                            );
                        })} 
                    </div>
                    {/* end item */}
                </div>
            </section>
            {/* end portfolio */}

        </>
    )
}

export default Portfolio